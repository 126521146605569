import { useState, createRef, useEffect } from 'react';
import axios from 'axios';
import SimpleInput from './Components/SimpleInput';
import Table from './Components/Table';
import TableLocation from './Components/TableLocation';
import TableCompanies from './Components/TableCompanies';
import TableCont1 from './Components/TableCont1';
import TableCont2 from './Components/TableCont2';
import TableMostComIssues from './Components/TableMostComIssues';
import InputWithButton from './Components/InputWithButton';
import Plot from 'react-plotly.js';
import PaginationComp from './Components/PaginationComp';
import NotificationOK from './Components/NotificationOK';
import {
  CodeIcon,
  StarIcon,
  ShareIcon,
  ArrowRightIcon,
  PresentationChartBarIcon,
  TagIcon,
  ExternalLinkIcon,
  QuestionMarkCircleIcon,
  DownloadIcon,
  XCircleIcon,
  XIcon
} from '@heroicons/react/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const backendApi = process.env.REACT_APP_ENV === 'development' ? "http://127.0.0.1:5000/" : "https://predict.paintedcave.xyz/";
const iframeSrc = "https://redash.paintedcave.xyz/public/dashboards/5v5zqkKf35oy1hLWTQEaNEj2EVrsP2MxBxuD2cQX?org_slug=default";

const tabList = ["Charts", "Repositories", "Search", "Labels"];
const repoTabList = ["Issues", "Analytics"];

const LIMITPERPAGE = 20;
const ISSUESLIMITPERPAGE = 5;

const predictionStrings = ["bug", "question", "feature", "security"];

function delay(t) {
  return new Promise(resolve => setTimeout(resolve, t));
}

const SimpleStat = ({ title, subtitle, value }) => {
  return (
    <div
      className="px-4 py-5 sm:p-6"
    >
      <dt className="text-base font-normal text-gray-900">{title}</dt>
      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
          {value}
          <span className="ml-2 text-sm font-medium text-gray-500">{subtitle}</span>
        </div>
      </dd>
    </div>
  )
}

export default function GithubOrg() {

  const [fetchingOrg, setFetchingOrg] = useState(false);
  const [inputUrl, setInputUrl] = useState("");
  const [orgFetchError, setOrgFetchError] = useState("");

  const [currentReposPage, setCurrentReposPage] = useState(0);
  const [currentIssuesPage, setCurrentIssuesPage] = useState(0);

  const [selectedTab, setSelectedTab] = useState(0);

  const [loadingIssues, setLoadingIssues] = useState(true);
  const [org, setOrg] = useState(null);
  const [repos, setRepos] = useState([]);
  const [issues, setIssues] = useState([]);
  const [currentIssues, setCurrentIssues] = useState([]);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [selectedRepoData, setSelectedRepoData] = useState(null);

  const [chartsData, setChartsData] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const [communityData, setCommunityData] = useState(null);
  const [repoChartsData, setRepoChartsData] = useState(null);
  const [repoStatsData, setRepoStatsData] = useState(null);

  const [selectedTabInRepo, setSelectedTabInRepo] = useState(0); //repoTabList: ["Issues", "Analytics"]

  const [okNotifTitle, setOkNotifTitle] = useState("");
  const [okNotifText, setOkNotifText] = useState("");
  const [okNotifShow, setOkNotifShow] = useState(true);

  const [repoSearchInput, setRepoSearchInput] = useState("");

  useEffect(() => {
    console.log("test");
  }, [currentIssues]);

  const fetchOrg = async (inUrl) => {

    //if (!inputUrl) return setOrgFetchError("Please enter a valid URL");

    //Improve this, maybe with a reducer?
    setOrg(null); setFetchingOrg(true);
    setSelectedTab(0); setOrgFetchError(""); setCurrentIssues([]);
    setCurrentReposPage(0); setRepos([]); setIssues([]); setSelectedTabInRepo(0);
    setChartsData(null); setStatsData(null); setSelectedRepo(null);

    try {
      console.log("Fetching org");
      let orgToReq;
      if (inUrl.includes('.com/')) orgToReq = (inUrl ? inUrl.substring(inUrl.indexOf(".com/") + 5) : "allenai")
      else orgToReq = inUrl;
      let startTime = Date.now();
      const orgRes = await axios(backendApi + "organizations/" + orgToReq);
      let endTime = Date.now();
      console.log("123");
      console.log("Time for main req", (startTime - endTime) / 1000);
      setOrg(orgRes.data.orgInfo);
      const reposSortedByStars = orgRes.data.repos.sort((a, b) => (a.stars < b.stars) ? 1 : -1);
      setRepos(reposSortedByStars);
      setIssues(orgRes.data.issues);

      console.log("Finished fetching org");
      console.log("Fetching analytics");
      axios(backendApi + `organizations/${orgToReq}/analytics`)
        .then((res) => {
          let charts = res.data.charts;
          let stats = res.data.stats;
          let community = res.data.community;
          console.log(stats.topIssueSubmitters);
          charts["issuesOverTime"].map(obj => ({ ...obj, type: "scatter", mode: "lines", }));
          //I should repeat that ^ for every chart. Do a loop.
          setChartsData(charts);
          setStatsData(stats);
          setCommunityData(community);
        });

      setTimeout(() => { setFetchingOrg(false); }, 350);
    } catch (err) {
      console.log(err);
      setFetchingOrg(false);
      alert("err fetching org");
    }
  }


  const selectRepo = async (newRepo) => {

    if (selectedRepo === newRepo) return;
    setLoadingIssues(true); setCurrentIssuesPage(0); setRepoChartsData(null); setSelectedRepo(newRepo);
    let filteredIssues = issues.filter((issue) => issue.repo == newRepo);
    axios(backendApi + "organizations/" + org.org + "/repos/" + newRepo + "/analytics")
      .then((res) => {
        let charts = res.data["charts"];
        let stats = res.data["stats"];
        console.log(stats);
        charts["issuesByLabel"].map(obj => ({ ...obj, type: "scatter", mode: "lines", }));
        setRepoChartsData(charts);
        setRepoStatsData(stats);
      });
    setCurrentIssues(filteredIssues);
    setSelectedRepoData(repos.find((repo) => repo.repo == newRepo));
    setLoadingIssues(false);
  }

  const applyLabel = async (issueNumber, labelToApply) => {
    try {
      const access_token = JSON.parse((await localStorage.getItem('tokenData'))).access_token[0];
      const headers = {
        'Github-Authorization': access_token,
        'Content-Type': 'application/json'
      }
      const applyRes = await axios.post(
        backendApi + `github/repos/${org.org}/${selectedRepo}/issues/${issueNumber}/labels`,
        {
          "labels": [labelToApply],
        }, { headers: headers });

      alert(`Label "${labelToApply}" successfully applied to issue #${issueNumber}`)
      const indexOfIssue = currentIssues.findIndex((iss) => iss.number === issueNumber)
      let newLabels = currentIssues[indexOfIssue].labels.split(',');
      newLabels = newLabels.filter(l => l !== labelToApply);
      newLabels.push(labelToApply)
      newLabels = newLabels.join(',');
      const updatedIssue = { ...currentIssues[indexOfIssue], labels: newLabels };
      const curIssCopy = currentIssues;
      curIssCopy[indexOfIssue] = updatedIssue;
      setCurrentIssues(curIssCopy);
      setSelectedTab(0); setSelectedTab(1);
      return;
    } catch (err) {
      console.log(err);
      alert("Error applying label: unauthorized user, not signed in, or app not installed on repo");
    }
  }

  const deleteLabel = async (issueNumber, labelToDelete) => {
    console.log("aca", currentIssues);
    try {
      const access_token = JSON.parse((await localStorage.getItem('tokenData'))).access_token[0];
      const headers = {
        'Github-Authorization': access_token,
        'Content-Type': 'application/json'
      }
      const deleteRes = await axios.delete(
        backendApi + `github/repos/${org.org}/${selectedRepo}/issues/${issueNumber}/labels/${labelToDelete}`, { headers: headers });
      //alert(`Label "${labelToDelete}" successfully deleted from issue #${issueNumber}`);
      const indexOfIssue = currentIssues.findIndex((iss) => iss.number === issueNumber)
      let newLabels = currentIssues[indexOfIssue].labels.split(',');
      newLabels = newLabels.filter(l => l !== labelToDelete);
      newLabels = newLabels.join(',');
      const updatedIssue = { ...currentIssues[indexOfIssue], labels: newLabels };
      const curIssCopy = currentIssues;
      curIssCopy[indexOfIssue] = updatedIssue;
      setCurrentIssues(curIssCopy);
      setSelectedTab(0); setSelectedTab(1);
      return;
    } catch (err) {
      console.error(err);
      alert("Error deleting label: label doesn't exist, unauthorized user, not signed in, or app not installed on repo");
    }
  }

  const indexLastR = (currentReposPage + 1) * LIMITPERPAGE;
  const indexFirstR = indexLastR - LIMITPERPAGE;


  //Improve this in the future - maybe do this stuff inside the comp?

  const indexLastI = (currentIssuesPage + 1) * ISSUESLIMITPERPAGE;
  const indexFirstI = indexLastI - ISSUESLIMITPERPAGE;

  //Change page
  const paginateRepos = (pageNumber) => setCurrentReposPage(pageNumber);
  const paginateIssues = (pageNumber) => setCurrentIssuesPage(pageNumber);

  return (
    <div>
      <div className="mx-auto px-4 sm:px-6 md:px-6">
        {/* <NotificationOK title={okNotifTitle} text={okNotifText} show={okNotifShow} /> */}
        <div className="flex flex-col p-3 md:flex-row justify-between items-start mb-10 border-b-2 border-gray-200 pb-6 md:pb-4 gap-y-5 md:gap-y-0">
          <h1 className="text-4xl font-bold text-gray-800 md:text-left text-center">Github Organization Dashboard</h1>
          <div className="flex flex-col items-center justify-start text-center">
            <InputWithButton value={inputUrl}
              onInputChange={(e) => setInputUrl(e.target.value)} onButtonClick={() => fetchOrg(inputUrl)}
              buttonText={"Fetch Organization"} placeHolder="GitHub Org (e.g. 'allenai')" icon={<ArrowRightIcon className="w-6 h-6" aria-hidden="true" />}
              errorMessage={orgFetchError} isLoading={fetchingOrg}
            />
            <div className="flex flex-row mt-2 gap-x-2">
              <button
                onClick={() => { setInputUrl("allenai"); fetchOrg("https://github.com/allenai") }}
                className="bg-indigo-400 text-white text-sm md:text-md font-semibold px-3 py-1 md:py-2 gap-x-4 items-center rounded-md inline-block"
              >allenai</button>
              <button
                onClick={() => { setInputUrl("electronicarts"); fetchOrg("https://github.com/electronicarts") }}
                className="bg-indigo-400 text-white text-sm md:text-md font-semibold px-3 py-1 md:py-2 gap-x-4 items-center rounded-md inline-block"
              >electronicarts</button>
              <button
                onClick={() => { setInputUrl("openai"); fetchOrg("https://github.com/openai") }}
                className="bg-indigo-400 text-white text-sm md:text-md font-semibold px-3 py-1 md:py-2 gap-x-4 items-center rounded-md inline-block"
              >openai</button>
              <button
                onClick={() => { setInputUrl("jupyter"); fetchOrg("https://github.com/jupyter") }}
                className="bg-indigo-400 text-white text-sm md:text-md font-semibold px-3 py-1 md:py-2 gap-x-4 items-center rounded-md inline-block"
              >jupyter</button>
            </div>
          </div>
        </div>
        {fetchingOrg && <div className="flex flex-row gap-x-2">
          <svg className="animate-spin h-6 w-6 text-indigo-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <h1 className="text-indigo-300 text-lg font-semibold">
            Loading organization. Please be patient, as large organizations may take some time.
          </h1>
        </div>}
        {org && !fetchingOrg && <div>
          <div className="grid grid-cols-1 md:grid-cols-6 xl:grid-cols-7 md:gap-3">
            <div className="col-span-2 flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-2 rounded-md p-3 bg-white">
                <div className="flex flex-row gap-x-4 -mt-8">
                  {org.avatar_url && <img src={org.avatar_url} className="w-10 h-10 rounded-md"></img>}
                  <a href={org.html_url} target="_blank"><h1 className="text-gray-700 font-bold text-3xl">{org.name !== "False" && org.name ? org.name : org.org}</h1></a>
                </div>
                {org.location && <p><b>Location</b>: {org.location}</p>}
                {org.email && <p><b>Email</b>: {org.email}</p>}
                {org.blog && <p><b>Blog</b>: <a href={org.blog} target="_blank">{org.blog}</a></p>}
              </div>

              <div className="shadow-sm rounded-md bg-white dark:bg-gray-800 p-4 cursor-pointer transform duration-300 hover:-translate-y-1"
                onClick={() => setSelectedTab(0)}>
                <div className="flex-row gap-4 flex justify-between items-center">
                  <div className="flex flex-row items-center gap-x-4">
                    <div className="flex-shrink-0">
                      <PresentationChartBarIcon className="w-6 h-6 text-green-300" aria-hidden="true" />
                    </div>
                    <span className="text-gray-600 dark:text-white text-lg font-semibold">
                      Issues Overview
                    </span>
                  </div>
                  <ArrowRightIcon className="w-6 h-6 text-green-300" aria-hidden="true" />
                </div>
              </div>

              <div className="shadow-sm rounded-md bg-white dark:bg-gray-800 p-4 cursor-pointer transform duration-300 hover:-translate-y-1"
                onClick={() => setSelectedTab(2)}>
                <div className="flex-row gap-4 flex justify-between items-center">
                  <div className="flex flex-row items-center gap-x-4">
                    <div className="flex-shrink-0">
                      <PresentationChartBarIcon className="w-6 h-6 text-green-300" aria-hidden="true" />
                    </div>
                    <span className="text-gray-600 dark:text-white text-lg font-semibold">
                      Community Analytics
                    </span>
                  </div>
                  <ArrowRightIcon className="w-6 h-6 text-green-300" aria-hidden="true" />
                </div>
              </div>

              <a className="shadow-sm rounded-md bg-white dark:bg-gray-800 p-4 cursor-pointer transform duration-300 hover:-translate-y-1"
                href={'https://github.com/apps/unwrap-app/installations/new'} target="_blank"
              >
                <div className="flex flex-col gap-y-1">
                  <div className="flex-row gap-4 flex justify-between items-center">
                    <div className="flex flex-row items-center gap-x-4">
                      <div className="flex-shrink-0">
                        <DownloadIcon className="w-6 h-6 text-red-300" aria-hidden="true" />
                      </div>
                      <span className="text-gray-600 dark:text-white text-lg font-semibold">
                        Install App on your repos
                      </span>
                    </div>
                    <ArrowRightIcon className="w-6 h-6 text-red-300" aria-hidden="true" />
                  </div>
                  <p className="text-sm font-semibold text-gray-400">This allows you to apply labels on issues</p>
                </div>
              </a>

              <div className="flex flex-col gap-y-2 bg-white rounded-md p-3 mt-8">
                <div className="flex flex-col -mt-8">
                  <h1 className="text-gray-700 font-bold text-3xl">Repositories</h1>
                  <p className="text-gray-400 font-semibold text-md">Select a repo to label issues and view more info</p>
                  <SimpleInput placeHolder="Search..." onChange={(e) => { setRepoSearchInput(e.target.value); }} />
                </div>

                <ul className="grid grid-cols-1 gap-y-3">
                  {repos && repos?.filter(elem => repoSearchInput !== "" ? elem.repo.toLowerCase().search(repoSearchInput.toLowerCase()) != -1 : true).slice(indexFirstR, indexLastR).map((repo, idx) => {
                    return (
                      {/* <a href={repo.html_url} target="_blank"> */ },
                      <li key={idx} className="rounded-md transform duration-300 hover:bg-gray-100 border-b-2 pb-2 border-gray-200 cursor-pointer"
                        onClick={() => {

                          setSelectedTab(1); selectRepo(repo.repo);
                        }}
                      >
                        <div className="w-full flex items-center justify-between p-2 flex-row">
                          <div className="flex flex-col gap-y-1">
                            <div className="flex items-center space-x-3" >
                              <div className="flex flex-row gap-x-3 items-center">
                                <CodeIcon className="w-3 h-3 text-gray-500" aria-hidden="true" />
                                <h3 className="text-indigo-600 italic text-sm font-medium line-clamp-2">{repo.repo}</h3>
                              </div>
                            </div>
                            <p className="text-gray-700 text-xs font-bold line-clamp-3">{repo.description}</p>
                            <div className="flex flex-row gap-x-4">
                              <p className="text-gray-400 text-sm font-medium">Open Issues: {issues.filter((iss) => iss.repo === repo.repo).length}</p>
                              <p className="text-gray-400 text-sm font-medium">Forks: {repo.forks}</p>
                              <p className="text-gray-400 text-sm font-medium">Stars: {repo.stars}</p>
                            </div>
                          </div>

                        </div>
                      </li>
                      /* </a> */
                    )
                  })}
                  <PaginationComp pageCount={Math.ceil(repos.length / LIMITPERPAGE)} onPageChangeFunc={paginateRepos} />
                </ul>
              </div>
            </div>

            <div className="md:col-span-4 xl:col-span-5 flex flex-col mt-12 md:mt-0">
              <div className="grid gap-x-4 gap-y-4">
                <div className="flex flex-col gap-y-2 bg-white rounded-md p-3 border-b-4 ">
                  <div className="flex flex-col gap-x-4 -mt-8">
                    {selectedTab == 0 &&
                      <><h1 className="text-gray-700 font-bold text-3xl">Analytics</h1>
                        {chartsData && statsData ? (
                          <div className="flex-col">
                            <dl className="mt-5 mb-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-5 md:divide-y-0 md:divide-x">
                              <SimpleStat title="Total Open Issues" value={issues.length} />

                              <SimpleStat title="Opened Issues" subtitle={`last 30d`} value={statsData["opened"]["30d"]} />
                              <SimpleStat title="Opened Issues" subtitle={`last 7d`} value={statsData["opened"]["7d"]} />
                              <SimpleStat title="Closed Issues" subtitle={`last 30d`} value={statsData["closed"]["30d"]} />
                              <SimpleStat title="Closed Issues" subtitle={`last 7d`} value={statsData["closed"]["7d"]} />
                            </dl>
                            <Plot className="shadow inline-block"
                              data={chartsData["issuesOverTime"]}
                              layout={{ width: 1000, height: 540, title: 'Open Issues by Repo' }}
                            />
                            <div className="flex flex-col mt-4">
                              <h1 className="text-gray-500 font-semibold p-2 text-md">Top Issue Submitters</h1>
                              <Table dataArr={statsData.topIssueSubmitters} limitPerPage={10} />
                            </div>
                          </div>
                        )
                          : (<h1 className="text-gray-500 font-semibold p-2 text-md">Loading...</h1>)}
                      </>}

                    {repos && selectedRepo && selectedTab === 1 && <>
                      <div className="flex flex-row justify-between">
                        <a href={`https://github.com/${org.org}/${selectedRepo}`} target="_blank"><h1 className="text-gray-700 font-bold text-3xl" id="repoInfo">{selectedRepo}</h1></a>
                        <div className="flex flex-row gap-x-4">
                          {repoTabList.map((rTabName, idx) => {
                            return <h1 className={classNames(selectedTabInRepo == idx ? "text-gray-700 text-3xl" : "text-gray-400 text-2xl", "font-bold cursor-pointer")}
                              onClick={() => setSelectedTabInRepo(idx)}>
                              {rTabName}
                            </h1>
                          })}
                        </div>
                      </div>
                      <ul className="grid grid-cols-1 gap-y-2">
                        {
                          loadingIssues ? (
                            <h1 className="text-gray-400 font-bold text-2xl">Loading...</h1>
                          ) : (
                            <>
                              <div className="flex-col">
                                <dl className="mt-5 mb-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-4 md:divide-y-0 md:divide-x">
                                  <SimpleStat title="Current Open Issues" subtitle={``} value={currentIssues.length} />
                                  <SimpleStat title="Unlabeled Issues" subtitle={``} value={currentIssues.filter((iss) => iss.labels === "").length} />
                                  <SimpleStat title="Predicted Labels" subtitle={``} value={currentIssues.filter((iss) => iss.pred).length} />
                                </dl>
                              </div>

                              {selectedTabInRepo == 1 && <>{repoChartsData && repoStatsData ? (
                                <>
                                  <Plot
                                    className="shadow inline-block"
                                    data={repoChartsData["issuesByLabel"]}
                                    layout={{ width: 1000, height: 540, title: 'Issues by Label' }}
                                  />
                                  <div className="grid grid-cols-1 mt-4 gap-x-6 gap-y-6">
                                    <div>
                                      <h1 className="text-gray-500 font-semibold p-2 text-md">Top Issue Submitters</h1>
                                      <Table dataArr={repoStatsData.topIssueSubmitters} limitPerPage={10} />
                                    </div>
                                    <div>
                                      <h1 className="text-gray-500 font-semibold p-2 text-md">Most Commented Issues</h1>
                                      <TableMostComIssues dataArr={repoStatsData.mostCommentedIssues} limitPerPage={10} />
                                    </div>
                                  </div>
                                </>) : (<h1 className="text-gray-400 font-bold text-2xl">Loading analytics...</h1>)}</>}

                              {selectedTabInRepo == 0 && <>{issues && currentIssues && currentIssues.slice(indexFirstI, indexLastI).length > 0 ? (
                                <>
                                  <PaginationComp pageCount={Math.ceil(currentIssues.length / ISSUESLIMITPERPAGE)} onPageChangeFunc={paginateIssues} marginPagesDisplayed={1} />
                                  {currentIssues.slice(indexFirstI, indexLastI).map((issue, idx) => {
                                    console.log("hmmm", issue);
                                    let issueUrl = `https://github.com/${org.org}/${selectedRepo}/issues/${issue.number}`
                                    return (
                                      <li key={idx} className="rounded-md bg-gray-100 bg-opacity-60 transform duration-300 hover:bg-opacity-100 px-4 py-2 border-gray-200">
                                        <div className="items-center justify-between p-2">
                                          <div className="flex flex-col gap-y-1">
                                            <div className="flex flex-row items-center justify-between">
                                              <a href={issueUrl} target="_blank">
                                                <div>
                                                  <div className="flex flex-row items-center gap-x-2">
                                                    <TagIcon className="w-5 h-5 text-gray-500" aria-hidden="true" />
                                                    <div className="flex flex-col">
                                                      <p className="text-gray-700 text-sm font-bold line-clamp-3">{issue.title}</p>
                                                      <p className="text-gray-400 -mt-1 text-xs font-medium">by {issue.author_username}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </a>
                                              <div className="flex flex-row gap-x-3">
                                                <div className="flex flex-row gap-x-3">
                                                  {issue.labels.split(',').map((label) => {
                                                    console.log(label);
                                                    return (
                                                      label !== "" &&
                                                      <div className="relative flex flex-row gap-x-1 items-center px-2 py-1 bg-gray-200 rounded-md">
                                                        <XIcon className="absolute -right-1 -top-1 w-3 h-3 text-white bg-red-500 opacity-60 rounded-xl p-0 transform hover:scale-110 hover:opacity-100 duration-300 cursor-pointer"
                                                          onClick={() => { deleteLabel(issue.number, label) }}
                                                        />
                                                        <p className="text-gray-500 text-xs font-medium">{label}</p>
                                                      </div>
                                                    );
                                                  })}
                                                </div>
                                                <a href={issueUrl} target="_blank">
                                                  <ExternalLinkIcon className="w-5 h-5 text-gray-500 transform hover:scale-110 hover:text-gray-700 duration-300" aria-hidden="true" />
                                                </a>
                                              </div>
                                            </div>
                                            <p className="text-gray-800 text-sm font-normal line-clamp-3 overflow-ellipsis">{issue.body}</p>
                                            {/* <div className="flex flex-row gap-x-2 ">
                                              test labels:
                                              <span className="py-1 px-2 flex w-10 items-center text-green-800 text-xs font-medium bg-green-200 rounded-md cursor-pointer"
                                                onClick={() => applyLabel(issue.number, "docs")}>
                                                docs
                                              </span>
                                              <span className="py-1 px-2 flex w-10 items-center text-green-800 text-xs font-medium bg-green-200 rounded-md cursor-pointer"
                                                onClick={() => applyLabel(issue.number, "bug")}>
                                                bug
                                              </span>
                                            </div> */}
                                            {issue.pred &&
                                              <div className="flex flex-col">
                                                <div className="flex flex-row border-t-2 border-gray-200 mt-2 pt-2 justify-between">
                                                  <div className="flex flex-row gap-x-4">
                                                    <p className="text-gray-600 text-md font-medium">Predicted:</p>

                                                    <span className="flex py-1 px-2 items-center text-green-800 text-xs font-medium bg-green-200 rounded-md cursor-pointer"
                                                      onClick={() => applyLabel(issue.number, issue.pred)}>
                                                      {issue.pred}
                                                    </span>
                                                    {/* <span className="flex py-1 px-2 items-center text-gray-500 text-xs font-medium bg-white rounded-md">
                                                        Apply all labels
                                                      </span> */}
                                                  </div>
                                                  <div className="flex">
                                                    <Tooltip tooltipText="Tap a label to apply it">
                                                      <QuestionMarkCircleIcon className="w-5 h-5 text-gray-800" />
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                                {/* <p className="text-gray-400 text-sm font-medium">Tap a label to apply it on GitHub</p> */}

                                              </div>}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                  {/*  <Pagination itemsPerPage={issuesPerPage} totalItems={currentIssues.length} paginate={paginateIssues} justifyEnd={true} />  */}
                                </>
                              ) : (
                                <h1 className="text-gray-400 font-bold text-2xl mt-4">This repo has no issues.</h1>
                              )
                              }</>}
                            </>)
                        }
                      </ul>
                    </>}

                    {selectedTab == 2 &&
                      <><h1 className="text-gray-700 font-bold text-3xl">Community</h1>
                        {chartsData && statsData && communityData ? (
                          <div className="flex-col">
                            <div className="grid mt-4 grid-cols-2 gap-x-6 gap-y-6" >
                              <div>
                                <h1 className="text-gray-500 font-semibold p-2 text-md">Top Companies of Community Members</h1>
                                <TableCompanies dataArr={communityData.companies} limitPerPage={10} />
                              </div>
                              <div>
                                <h1 className="text-gray-500 font-semibold p-2 text-md">Top Locations of Community Members</h1>
                                <TableLocation dataArr={communityData.locations} limitPerPage={10} />
                              </div>
                            </div>

                            <div className="grid mt-4 grid-cols-2 gap-x-6 gap-y-6" >
                              <div>
                                <h1 className="text-gray-500 font-semibold p-2 text-md">Top Overall Contributors</h1>
                                <TableCont1 dataArr={communityData.contributorsByNumContributions} limitPerPage={10} />
                              </div>
                              <div>
                                <h1 className="text-gray-500 font-semibold p-2 text-md">Most Influential Contributors</h1>
                                <TableCont2 dataArr={communityData.contributorsByNumFollowers} limitPerPage={10} />
                              </div>
                            </div>
                            <div className="mt-6">
                              <p className="text-gray-400">Data based on GitHub profiles. Not every profile has this information available.</p>
                            </div>
                          </div>
                        )
                          : (<h1 className="text-gray-500 font-semibold p-2 text-md">Loading...</h1>)}
                      </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div >
  )
}

function Tooltip({ children, tooltipText }) {
  const tipRef = createRef(null);
  function handleMouseEnter() {
    tipRef.current.style.opacity = 1;
    tipRef.current.style.marginLeft = "20px";
  }
  function handleMouseLeave() {
    tipRef.current.style.opacity = 0;
    tipRef.current.style.marginLeft = "10px";
  }
  return (
    <div
      className="relative items-center flex "
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="absolute whitespace-no-wrap bg-gray-700 text-white px-4 py-2 rounded flex items-center transition-all duration-150 text-sm"
        style={{ left: "100%", opacity: 0 }}
        ref={tipRef}
      >
        <div
          className="bg-gray-700 h-3 w-3 absolute "
          style={{ left: "-6px", transform: "rotate(45deg)" }}
        />
        {tooltipText}
      </div>
      {children}
    </div>
  );
}



import ReactPaginate from 'react-paginate';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

export default function PagComp({ pageCount, onPageChangeFunc, marginPagesDisplayed }) {
    return (
        <ReactPaginate
            previousLabel={
                <div className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </div>}
            nextLabel={
                <div className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </div>}
            containerClassName={"inline-flex rounded-md shadow-sm -space-x-px z-0 select-none justify-end"}
            pageClassName={"relative cursor-pointer inline-flex items-center px-4 py-2 border text-sm font-medium select-none border-gray-300 text-gray-500 hover:bg-gray-50"}
            activeClassName={"z-10 cursor-pointer bg-indigo-50 border-indigo-500 text-indigo-600"}
            pageLinkClassName={"block"}
            breakClassName={"cursor-pointer relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 select-none"}
            breakLabel={"..."} pageCount={pageCount}
            marginPagesDisplayed={marginPagesDisplayed || 0} pageRangeDisplayed={4} onPageChange={(e) => {onPageChangeFunc(e.selected); console.log(e);}}
        />
    );
}
/* This example requires Tailwind CSS v2.0+ */
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'

import { Switch, Route, BrowserRouter as Router, NavLink, Redirect } from 'react-router-dom';
import TextAttackDash from './TextAttackDash';
import GithubRepo from './GithubRepo';
import Github from './Github';
import Sandbox from './Sandbox';
import logo from './logo.png';
import LoginGithub from 'react-login-github';
import axios from 'axios';

const navigation = [
  { name: 'Unwrap.ai', href: '/github', current: true },
  /* { name: 'TextAttack', href: '/textattack', current: false },
  { name: 'Repos', href: '/repos', current: false }, */
]

const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const backendApi = process.env.REACT_APP_ENV === 'development' ? "http://127.0.0.1:5000/" : "https://predict.paintedcave.xyz/";
const githubLoginUrl = backendApi + "login/github";
const githubGetTokenUrl = backendApi + "login/github/callback";



export default function Dashboard() {

  const [user, setUser] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onGHLoginSuccess = async (response) => {
    const code = response.code;
    const tokenRes = await axios(githubGetTokenUrl + `?code=${code}`);
    console.log(tokenRes);
    const userData = tokenRes.data.userData;
    const tokenData = tokenRes.data.tokenData;
    //Use Cookies on a prod version for more safety
    await localStorage.setItem('userData', JSON.stringify(userData));
    await localStorage.setItem('tokenData', JSON.stringify(tokenData));
    setUser(userData);
  }
  const onGHLoginFailure = response => console.error(response);

  useEffect(() => {
    const initialCheck = async () => {
      let userData = await localStorage.getItem('userData');
      let tokenData = await localStorage.getItem('userData');
      if (userData && tokenData) {
        setUser(JSON.parse(userData));
      } else {
        if (tokenData) {
          await localStorage.removeItem('tokenData');
        }
        if (userData) {
          await localStorage.removeItem('userData');
        }
      }
    }
    initialCheck();
  }, []);

  const onSignOut = async () => {
    await localStorage.removeItem('userData');
    await localStorage.removeItem('tokenData');
    setUser(null);
  }

  return (
    <Router>
      <div className="bg-gray-100 min-h-screen ">
        <div className="">
          <Disclosure as="nav" className="bg-white bg-opacity-30 border border-gray-200 ">
            {({ open }) => (
              <>
                <div className="xl:max-w-screen-2xl max-w-screen-lg mx-auto px-2 sm:px-4 lg:px-8">
                  <div className="flex justify-between h-16">
                    <div className="flex">
                      <div className="flex-shrink-0 flex items-center gap-x-5">
                        <img
                          className="block h-8 w-auto"
                          src={logo}
                          alt="Workflow"
                        />
                        {/* <h1 className="text-gray-700 font-bold text-2xl ">UnwrapAI</h1> */}
                      </div>
                      <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                        {navigation.map((item) => (
                          <NavLink
                            className={" border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"}
                            activeClassName={'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium'}
                            exact
                            to={item.href}
                          >
                            {item.name}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                    <div className="hidden sm:ml-6 sm:flex sm:items-center z-50">
                      {user ? (
                        <Menu as="div" className="ml-3 relative">
                          {({ open }) => (
                            <>
                              <div>
                                <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                  <span className="sr-only">Open user menu</span>
                                  <img className="h-8 w-8 rounded-full" src={user.avatar_url} alt="" />
                                  <h1 className='block px-3 py-2 text-sm font-semibold text-gray-700'>{user.login}</h1>
                                </Menu.Button>
                              </div>
                              <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items
                                  static
                                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                  <Menu.Item>
                                    <div className={'cursor-pointer hover:bg-gray-50 block px-4 py-2 text-sm text-gray-700'}
                                      onClick={() => onSignOut()}>
                                      Logout
                                    </div>
                                  </Menu.Item>
                                  {/*  {userNavigation.map((item) => (
                                    <Menu.Item key={item.name}>
                                      {({ active }) => (
                                        <a
                                          href={item.href}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700'
                                          )}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))} */}
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      ) : (
                        <LoginGithub clientId="Iv1.daeb9fa449a5b09d"
                          redirectUri={process.env.REACT_APP_ENV === 'development' ? "http://localhost:3000" : "https://demo.unwrap.ai"}
                          onSuccess={onGHLoginSuccess}
                          onFailure={onGHLoginFailure}
                        >
                          <div className="block">
                            <div
                              className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 gap-x-2"
                            >
                              <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                  fillRule="evenodd"
                                  d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="">Sign in with GitHub</span>
                            </div>
                          </div>
                        </LoginGithub>
                      )}

                    </div>
                    <div className="-mr-2 flex items-center sm:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="sm:hidden">
                  <div className="pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                      <NavLink
                        className={"block px-4 py-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 items-center border-l-4 text-lg font-medium"}
                        activeClassName={'block border-indigo-500 text-gray-900 items-center px-1 pt-1 border-l-4 text-lg font-medium'}
                        exact
                        to={item.href}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                  {/* <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">{user.name}</div>
                      <div className="text-sm font-medium text-gray-500">{user.email}</div>
                    </div>
                    <button className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1">
                    {userNavigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div> */}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <div className="pt-3 pb-10 xl:max-w-screen-2xl max-w-screen-lg mx-auto px-2 sm:px-4 lg:px-8">
            <main>
              <Switch>
                <Route exact path="/github" component={Github} />
                <Route exact path="/sandbox" component={Sandbox} />
                <Redirect to="/github" />
              </Switch>

            </main>
          </div>
        </div>
      </div>
    </Router >
  )
}




const GithubSignInComp = ({ url }) => {
  return (
    <div className="block">
      <a
        href={url}
        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 gap-x-2"
      >
        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
            clipRule="evenodd"
          />
        </svg>
        <span className="">Sign in with GitHub</span>
      </a>
    </div>
  )
}
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SimpleInput({ label, textBelow, errorMessage, placeHolder, ...props }) {
  return (

    <div className={props.className}>
      <label className="block text-sm font-medium text-gray-400">
        {label}
      </label>
      <div className="mt-1">
        <input
          onChange={props.onChange}
          type="text"
          name="text"
          id="text"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md w-full placeholder-gray-400"
          autoComplete="off"
          placeholder={placeHolder}
        />
      </div>
      <p className="mt-2 text-sm text-gray-500">
        {textBelow}
      </p>
    </div >
  )
}
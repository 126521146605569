import { useState } from 'react';
import PaginationComp from './PaginationComp';

const people = [
    { name: 'Jane Cooper', title: 'Regional Paradigm Technician', role: 'Admin', email: 'jane.cooper@example.com' },
    { name: 'Cody Fisher', title: 'Product Directives Officer', role: 'Owner', email: 'cody.fisher@example.com' },
    // More people...
]

export default function Table({ dataArr, limitPerPage }) {

    const [currentPage, setCurrentPage] = useState(0);
    const indexLast = (currentPage + 1) * limitPerPage;
    const indexFirst = indexLast - limitPerPage;
    const curData = dataArr?.slice(indexFirst, indexLast);

    const paginateData = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Issue
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Comments
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Creator
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {curData.map((data, idx) => {
                                    let issueTitle = data[4].length > 70 ? data[4].substr(0, 70) + '...' : data[4];
                                    return (
                                        <tr key={idx} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 underline hover:text-blue-800 duration-300">
                                                <a href={"https://" + data[0]} target="_blank">{issueTitle}</a></td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{data[1]}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{data[2]}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="flex justify-end m-2">
                            <PaginationComp pageCount={Math.ceil(dataArr.length / limitPerPage)} onPageChangeFunc={paginateData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useState } from 'react';
import SimpleDropdown from './Components/SimpleDropdown';
import SimpleTextArea from './Components/SimpleTextArea';
import SimpleInput from './Components/SimpleInput';

import axios from 'axios';

import { modelList } from './TextAttackData/textattackModels';
import { recipeList } from './TextAttackData/textattackRecipes';

import Ansi from 'ansi-to-react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const cancelTokenSource = axios.CancelToken.source();

const scoreToPerc = (score) => (score * 100).toFixed(2);

const backendIp = "https://api.paintedcave.xyz" //This should be an env variable soon

//sentimentStringify(sentiment) Should do this to determine POS, NEG, NEU. - OJO: Showing NEU on (NEG, 0.6) should be DIFFERENT than showing NEU on (POS, 0.6) 

export default function TextAttackDash() {

  const [inputPhrases, setInputPhrases] = useState([""])
  const [inputModel, setInputModel] = useState(null);

  const [loadingPrediction, setLoadingPrediction] = useState(false);
  const [predictions, setPredictions] = useState(null);
  const [predictionDataset, setPredictionDataset] = useState(null);

  const [inputRecipe, setInputRecipe] = useState(null);
  const [loadingAttack, setLoadingAttack] = useState(false);
  const [perturbations, setPerturbations] = useState(null);

  const getSentimentSinglePhrase = async () => {

    setLoadingPrediction(true);
    if (loadingAttack) {
      setLoadingAttack(false);
      cancelTokenSource.cancel();
    }
    setPerturbations(null);
    try {
      const sentimentRes = await axios.post(`${backendIp}/textattack/sentimentAnalysis`, { 'model': inputModel, "phrases": inputPhrases });
      //Working only with a single phrase. Should be an array of results for multiple phrases/sentences.
      let phraseRes = sentimentRes.data.results;
      phraseRes = phraseRes.map((result) => {
        return { ...result, perc: scoreToPerc(result.score), label: result.label === "LABEL_1" ? "POSITIVE" : "NEGATIVE" }
      });
      setPredictions(phraseRes);
      setPredictionDataset(sentimentRes.data.newDataset);
    } catch (err) {
      console.log(err);
      alert(err.response.data);
    }
    setTimeout(() => { setLoadingPrediction(false) }, 350);

  }

  const attackDataset = async () => {
    setLoadingAttack(true);
    try {
      if (!predictionDataset) return alert("No dataset found. Please run Get Sentiment first");
      const attackRes = await axios.post(`${backendIp}/textattack/attackDataset`, { 'model': inputModel, "dataset": predictionDataset, 'recipe': inputRecipe }, { cancelToken: cancelTokenSource.token });
      //Same thing, working with a single phrase.
      const perturbationRes = attackRes.data.results;
      setPerturbations(perturbationRes);
    } catch (err) {
      console.log(err);
      alert("Error, check console.");
    }
    setTimeout(() => { setLoadingAttack(false) }, 350);
  }


  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6 mb-10">
        <h1 className="text-2xl font-semibold text-gray-400">Dashboard</h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-6">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col gap-y-6">
            <SimpleDropdown label="Model" options={modelList} onChange={(e) => { setInputModel(e.target.value); }} />
            {
              inputPhrases.map((phrase, idx) => {
                return (
                  <div>
                    <SimpleTextArea label={`Phrase #${idx + 1}`} onChange={(e) => {
                      const list = [...inputPhrases];
                      list[idx] = e.target.value;
                      setInputPhrases(list)
                    }} />
                    {idx === inputPhrases.length - 1 && <button type="button" onClick={() => { setInputPhrases(old => [...old, ""]) }}
                      className="px-4 py-2 flex items-center text-sm justify-center rounded-md bg-gray-200 font-semibold text-gray-400 hover:bg-gray-300 duration-100 disabled:opacity-50">
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" /></svg>
                      <p>Add New Phrase</p>
                    </button>}
                  </div>
                )
              })
            }

            <div className="flex gap-x-3 mb-5">

              <button type="button" onClick={() => { getSentimentSinglePhrase() }}
                className="px-4 py-2 flex items-center justify-center rounded-md bg-indigo-700 font-semibold text-white hover:bg-indigo-600 duration-100 disabled:opacity-50"
                disabled={loadingPrediction || inputPhrases.length === 0}>
                {loadingPrediction && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
                <p className="text-lg">Get{loadingPrediction && 'ting'} Sentiment</p>
              </button>

            </div>
          </div>
          <div>
            <h1 className="block text-sm font-medium text-gray-700 mb-1">
              Sentiment Analysis
                  </h1>
            <div className="flex flex-col max-h-full rounded-xl bg-white p-5 gap-y-8">
              {predictions && predictions.map((pred, idx) => {
                return (
                  <div>
                    <div className="flex flex-col">
                      <p className="font-bold">Phrase #{idx + 1}</p>
                      <p><i>{pred.phrase}</i></p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-bold">Prediction:</p>
                      <p className={classNames("text-lg font-bold", pred.label === "POSITIVE" ? "text-green-500" : "text-red-500")}>
                        {pred.label} ({pred.perc}%)
                            </p>
                    </div>
                  </div>
                )
              })}
            </div >
            <div className="mt-5 flex flex-wrap justify-between gap-y-5">
              <SimpleDropdown label="Attack Type" options={recipeList} onChange={(e) => { setInputRecipe(e.target.value); }} disabled={loadingPrediction || !predictions} />
              <SimpleDropdown label="Adv. Perturbations" options={[1, 3, 5, 10]} disabled={true || loadingPrediction || !predictions} />
              <button type="button" onClick={() => { attackDataset(); }}
                className="px-4 py-2 flex items-center justify-center rounded-md bg-pink-500 font-semibold text-white hover:bg-pink-400 duration-100 
                      disabled:opacity-50"
                disabled={loadingAttack || loadingPrediction || !predictions}>
                {loadingAttack && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
                <p>Run{loadingAttack && 'ning'} TextAttack</p>
              </button>
            </div>
            <h1 className="block text-sm font-medium text-gray-700 mb-1 mt-5">
              TextAttack Results
                  </h1>
            <div className="flex flex-col max-h-full rounded-xl bg-white p-5 gap-y-8">
              {perturbations && perturbations.map((pert, idx) => {
                return (
                  <div>
                    <div className="flex flex-col">
                      <p className="font-bold">Phrase #{idx + 1}</p>
                      <p><Ansi>{pert.textDiff[0]}</Ansi></p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-bold">Perturbation:</p>
                      <p><Ansi>{pert.textDiff[1]}</Ansi></p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-bold">Goal Function:</p>
                      <p><Ansi>{pert.result}</Ansi></p>
                    </div>
                  </div>
                );
              })}
            </div >
          </div>
        </div>
      </div>
    </div>

  );
}
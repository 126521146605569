exports.recipeList = [
  "BERTAttack2020",
  "TextFoolerJin2019",
  "BAEGarg2019",
  "CheckList2020",
  "DeepWordBugGao2018",
  "GeneticAlgorithmAlzantot2018",
  "FasterGeneticAlgorithmJia2019",
  "HotFlipEbrahimi2017",
  "IGAWang2019",
  "InputReductionFeng2018",
  "Kuleshov2017",
  "PSOZang2020",
  "PWWSRen2019"
]
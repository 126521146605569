function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function InputWithButton({ label, buttonText, errorMessage, onButtonClick, placeHolder, onInputChange, isLoading, icon, value, ...props }) {
  return (
    <div className="flex flex-col my-1">
      <div className="flex">
        <input
          onChange={onInputChange}
          type="text"
          name="text"
          id="text"
          className={classNames("p-4 mr-0 w-80 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-l-md placeholder-gray-400", errorMessage && "border-red-400 border border-r-0")}
          placeholder={placeHolder}
          value={value}

        />
        <button
          onClick={onButtonClick}
          className="bg-indigo-700 text-white text-sm md:text-md font-semibold px-5 py-1 md:py-4 gap-x-4 items-center rounded-r-md flex flex-row"
          disabled={isLoading}
        >
          {buttonText}
          {isLoading ? (<svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>) : icon}
        </button>
      </div>
      <p className="text-sm text-red-500 font-semibold ml-1">{errorMessage}</p>
    </div>
  )
}